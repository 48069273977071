import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar'
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'

const Wrapper = styled.div`
  margin: 0 auto 2rem;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const Author = styled.p`
  display: inline-block;
  margin-right: 1rem;
`

const Date = styled.p`
  display: inline-block;
  margin-right: 1rem;
`

const ReadingTime = styled.p`
  display: inline-block;
`

const Details = ({ author, date, timeToRead, children }) => {
  return (
    <Wrapper>
      <div>{children}</div>
      {author && <Author>Von {author}</Author>}
      <Date>
        <FontAwesomeIcon icon={faCalendar} /> {date}
      </Date>
      <ReadingTime>
        <FontAwesomeIcon icon={faClock} /> {`${timeToRead} min read `}
      </ReadingTime>
    </Wrapper>
  )
}

export default Details
