import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight'

const Container = styled.div`
  background: linear-gradient(${props => props.theme.colors.gradients.dark});
  color: white;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 2rem;

  a:not(:last-child) {
    display: block;
    margin-bottom: 1rem;
  }
`

const Headline = styled.h2`
  font-family: ${props => props.theme.fonts.body};
  font-size: ${props => props.theme.fontSizes.lg};
  margin-bottom: 1rem;
`

const Teaser = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 16px;
  color: ${props => props.theme.colors.black};
  padding: 1rem;

  &:hover {
    background: ${props => props.theme.colors.neutral.light};
  }
`

const TeaserHeadline = styled.h2`
  font-size: ${props => props.theme.fontSizes.md};
  margin-bottom: 0.5rem;
`

const Teasers = ({ jobKeywords, jobs }) => {
  const keywords = jobKeywords.map(keyword => keyword.title)
  const keywordsString =
    keywords.length < 2
      ? keywords
      : keywords.slice(0, -1).join(', ') + ' und ' + keywords.slice(-1)

  return (
    <Container>
      <Headline>
        Interessiert an <strong>{keywordsString}</strong>? Bewirb Dich bei uns!
      </Headline>
      {jobs.map((job, index) => (
        <a
          key={index}
          href={job.url}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Teaser title={`Bewirb dich als ${job.title}`}>
            <div>
              <TeaserHeadline>{job.title}</TeaserHeadline>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: '0.25rem' }}
                size="1x"
              />
              <small>{job.destination}</small>
            </div>
            <div>
              <FontAwesomeIcon icon={faChevronCircleRight} size="2x" />
            </div>
          </Teaser>
        </a>
      ))}
    </Container>
  )
}

export default Teasers
