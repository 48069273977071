import React from 'react'
import styled from '@emotion/styled'

const BaseTitle = styled.h1`
  font-size: ${props => (props.small ? '2em' : '3em')};
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
  margin: 0 0 3rem 0;
  margin: ${props => (props.small ? '1rem 0 4rem 0' : '0 0 3rem 0')};
  line-height: -0.022;

  span {
    margin: 0 0 0 0.25rem;
  }

  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const Title = props => {
  return <BaseTitle small={props.small}>{props.children}</BaseTitle>
}

export default Title
