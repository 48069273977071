import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { BaseLayout as Layout } from 'components/layout'
import { Container } from 'components/container'
import { Body as PageBody } from 'components/page'
import { Tag, Tags } from 'components/tag'
import {
  Hero,
  Links as PostLinks,
  Details as PostDetails,
} from 'components/post'
import { Teasers as JobTeasers } from 'components/job'
import { Author } from 'components/author'
import { SEO } from 'components/seo'

const ContentWrapper = styled.article`
  background: white;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  border-radius: 16px;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
`

const TagsContainer = styled(Tags)`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0rem;
`

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    metaDescription,
    heroImage,
    body,
    publishDate,
    tags,
    jobKeywords,
    author,
    job,
  } = data.contentfulPost

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <Hero title={title} image={heroImage} height={'50vh'} />
      <Container>
        <ContentWrapper>
          <PostDetails
            date={publishDate}
            timeToRead={body.childMarkdownRemark.timeToRead}
            author={author && author.name}
          >
            {tags && (
              <TagsContainer>
                {tags.map((data, index) => (
                  <Tag key={index} {...data} />
                ))}
              </TagsContainer>
            )}
          </PostDetails>
          <PageBody body={body} />
          {author && <Author author={author} />}
        </ContentWrapper>
        {jobKeywords && job && (
          <JobTeasers jobKeywords={jobKeywords} jobs={job} />
        )}
      </Container>
      <PostLinks previous={previous} next={next} basePath={basePath} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      jobKeywords {
        title
      }
      author {
        jobTitle
        name
        aboutme
      }
      job {
        title
        destination
        url
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
