import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/base'

const Wrapper = styled.div`
  margin: -2rem 0 0 0;
  padding: 0 1.5rem 2rem;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 0 1.5rem;
`

const PreviousLink = styled(Button)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Button)`
  margin-left: auto;
  order: 2;
`

const Links = props => {
  return (
    <Wrapper>
      <Box>
        {props.previous && (
          <PreviousLink to={`${props.basePath}/${props.previous.slug}/`}>
            &#8592; Vorheriger Post
          </PreviousLink>
        )}
        {props.next && (
          <NextLink to={`${props.basePath}/${props.next.slug}/`}>
            Nächster Post &#8594;
          </NextLink>
        )}
      </Box>
    </Wrapper>
  )
}

export default Links
