import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  position: relative;
  min-height: 300px;
  height: auto;
  margin-top: 2rem;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`
const BgImg = styled(Img)`
  min-height: 300px;
  height: 100%;

  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.314;
    background: linear-gradient(
      ${props => props.theme.colors.gradients.colorful}
    );
    mix-blend-mode: screen;
    pointer-events: none;
  }
`

const Title = styled.h1`
  z-index: 2;
  font-size: 2rem;
  font-weight: 600;
  position: absolute;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  padding: 0 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.25) 2px 2px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 3rem;
  }
`

const Hero = props => (
  <Wrapper height={props.height}>
    <BgImg
      alt={`${props.title} Cover`}
      fluid={props.image.fluid}
      loading={'lazy'}
    />
    <Title>{props.title}</Title>
  </Wrapper>
)

export default Hero
