import React from 'react'
import styled from '@emotion/styled'

const BaseBody = styled.div`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto;
  margin-bottom: 2rem;

  h1,
  h2,
  h3 {
    font-weight: 600;
    margin: 0 0 1rem 0;
  }

  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.15rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0 0 0.5rem 0;

    + h1,
    + h2,
    + h3 {
      margin-top: 2rem;
    }

    + ol,
    + ul {
      margin-bottom: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not(.anchor.before) {
    transition: 0.2s;
    color: ${props => props.theme.colors.info.base};
    position: relative;
    text-decoration: none;
    font-weight: 600;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 3px;
      background: linear-gradient(
        ${props => props.theme.colors.gradients.colorful}
      );
      transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover::before {
        left: 0;
        right: auto;
        width: 100%;
      }
    }

    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  em {
    font-style: italic;
  }

  ul li {
    list-style: disc;
    /* necessary for correct identation */
    text-indent: -22px;
    padding-left: 32px;
  }

  ol li {
    list-style: decimal;
    /* necessary for correct identation */
    text-indent: -18px;
    padding-left: 32px;
  }

  ul,
  ol {
    list-style-position: outside;
    margin: 0 0 2rem 0;

    li {
      list-style-position: inside;
      margin-bottom: 0.5rem;

      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2rem 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5rem;
  }

  span + em {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    display: block;
  }
`

const Body = props => {
  return (
    <BaseBody
      dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }}
    />
  )
}

export default Body
