import React from 'react'
import styled from '@emotion/styled'

const Root = styled.div`
  background: linear-gradient(
    ${props => props.theme.colors.gradients.colorful}
  );
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto;
  padding: 8px;
  border-radius: 20px;
`

const Wrapper = styled.div`
  background: white;
  border-radius: 16px;
  padding: 1rem;

  small {
    display: block;
    margin-bottom: 0.25rem;
  }
`

const Headline = styled.h2`
  font-size: ${props => props.theme.fontSizes.md};
  margin-bottom: 0.5rem;
`

const Author = ({ author: { jobTitle, name, aboutme } }) => {
  return (
    <Root>
      <Wrapper>
        <small>Geschrieben von</small>
        <Headline>
          <strong>{name}</strong> ({jobTitle})
        </Headline>
        {aboutme}
      </Wrapper>
    </Root>
  )
}

export default Author
